import React from 'react'
import pic01 from '../assets/img/main.jpg'
import pic02 from '../assets/img/sec_.jpg'
import Layout from '../components/layout'

/** @components */
import ViewGallery from '../components/gallery/gallery';

import { MailForm } from '../components/form/form';

const IndexPage = () => (
  <Layout>
    {/* <Header /> */}
    <section className="intro">
      <header>
        <h1>Offices for Rent in Malta</h1>
        <p>
          Never used existing office block available for rent close to Malta International Airport
          <br></br>To be leased directly from the owner.
          {/* <a href="https://twitter.com/ajlkn">@ajlkn</a> /{' '}
          <a href="https://html5up.net">HTML5 UP</a> */}
        </p>
        <ul className="actions">
          <li>
            <a className="button large" href="tel:+35699221562">
              Call +356 99 22 15 62
            </a>
          </li>
          <li>
            <a href="#contact" className="button primary large">
              Contact Now
            </a>
          </li>
        </ul>
      </header>
      <div className="content">
        <span className="image fill" data-position="center">
          <img src={pic01} alt="" />
        </span>
      </div>
    </section>

    <section id="first">
      <header>
        <h2>Why Malta?</h2>
      </header>
      <footer>
        <span className="image main">
          <img src={pic02} alt="" />
        </span>
      </footer>
      <div className="content">
        <p>
          Malta is considered as a <strong>highly attractive </strong>
          jurisdiction by foreign investors who wish to relocate a business to
          the island.
          <br></br>
          <br></br>
          Such advantages include:
          <br></br>
        </p>
        <ul>
          <li>Tax Regime</li>
          <li>Investments</li>
          <li>Business Opportunities</li>
          <li>Fiscal & Social benefits</li>
          <li>Distinctive Lifestyle</li>
          <li>Stable Economic Ecosystem</li>
        </ul>
      </div>
    </section>
    <section id="first">
      <header>
        <h2>Plans/Office Layouts</h2>
      </header>
      <div className="content">
        {/* <p>
          <strong>Some optional</strong> text relating to floor plans
        </p> */}
        <ul className="actions">
          <li>
            <a
              href="https://cdn.axier.io/kirkop_office_plans.zip"
              className="button primary large"
            >
              Download Plans
            </a>
          </li>
          {/* <li><a href="#" className="button large">Learn More</a></li> */}
        </ul>

        <div className="your-required-wrapper" style={{width: 100, height: 30}}>
          {/* <SwitchSelector
              options={options}
              onChange={onChange}
              initialSelectedIndex={initialSelectedIndex}
              backgroundColor={"#353b48"}
              fontColor={"#f5f6fa"}
          /> */}
        </div>

      </div>
    </section>
    <div className="carousel-cont">
      {/* @note ~ to add gallery component */}
      <ViewGallery></ViewGallery>
    </div>
    <section>
      <header>
        <h2>Specifications</h2>
      </header>
      <div className="content">
        <p>
          To be leased <strong>directly from the owner</strong>.
        </p>
        <ul className="feature-icons">
          <li className="icon solid fa-warehouse">1000 sq.m.</li>
          <li className="icon solid fa-bolt">Fully Energy Efficient</li>
          <li className="icon solid fa-plane-arrival">
            1km from International Airport
          </li>
        </ul>
        <p>
          1000 sq.m. of open plan office space near Malta International Airport
          <br></br>
          This new Business Centre / Commercial Building is about 1km from Malta
          International Airport.
          <br></br>
          <br></br>
          <ul>
            <li>The building is Fully Energy Efficient</li>
            <li>Conforms to US, European and other international standards</li>
            <li>Consists of one large high open space semi-basement</li>
            <li>3 floors {'('}Ground floor, 1st & 2nd floors{')'} of modern and well-designed open office space enjoying
              abundant natural light
            </li>
            <li>
              Spacious Penthouse at top roof level with a large roof garden/cocktail
              terrace area
            </li>
          </ul>
          Interested potential clients can contact the owner directly either on{' '}
          <a href="tel:+35699221562">+356 99 22 15 62</a>, or on{' '}
          <a href="tel:+33678713733">+33 (0)6 78 71 37 33</a> and by e-mail:{' '}
          <a href="mailto:eafarrugia@gmail.com">eafarrugia@gmail.com</a>
        </p>
      </div>
    </section>

    <section>
      <header id="contact">
        <h2>Get in touch</h2>
        <ul className="items">
          <li>
            <h3>Email</h3>
            <a href="mailto:eafarrugia@gmail.com">eafarrugia@gmail.com</a>
          </li>
          <li>
            <h3>Phone</h3>
            <a href="tel:+35699221562">+356 99 22 15 62</a> / <a href="tel:+33678713733">+33 (0)6 78 71 37 33</a>
          </li>
        </ul>
      </header>
      <footer>
        {/* <ul className="items">
          <li>
            <h3>Email</h3>
            <a href="mailto:eafarrugia@gmail.com">eafarrugia@gmail.com</a>
          </li>
          <li>
            <h3>Phone</h3>
            <a href="tel:+35699221562">+356 99 22 15 62</a>
          </li>
        </ul> */}
      </footer>
      <div className="content">
        <p>
          <strong>Otherwise</strong> Please fill in your details here and we'll
          get right back to you.
        </p>
        {/* @note ~ to add form */}
        <MailForm></MailForm>
      </div>
    </section>

    <div className="copyright">
      &copy; Untitled. All rights reserved. Design:{' '}
      <a href="https://www.codebrew.cc">Codebrew Ltd</a>.
    </div>
  </Layout>
)

export default IndexPage
