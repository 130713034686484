export const images = [{
        src: 'https://cdn.axier.io/b4165ce3/thumbs/1.jpg',
        width: 300,
        height: 214,
        tags: [{
            value: 'Basement',
            title: 'Basement'
        }],
        caption: 'After Rain (Jeshu John - designerspics.com)',
    },
    {
        src: 'https://cdn.axier.io/b4165ce3/thumbs/2.jpg',
        width: 350,
        height: 249,
        tags: [{
            value: 'Ground Floor',
            title: 'Ground Floor'
        }],
        caption: 'Ground Floor',
    },
    {
        src: 'https://cdn.axier.io/b4165ce3/thumbs/3.jpg',
        width: 400,
        height: 285,
        tags: [{
            value: 'First Floor',
            title: 'First Floor'
        }],
        caption: 'First Floor',
    },
    {
        src: 'https://cdn.axier.io/b4165ce3/thumbs/3.2.jpg',
        width: 1916,
        height: 1354,
        tags: [{
            value: 'Second Floor',
            title: 'Second Floor'
        }],
        caption: 'Second Floor',
    },
    {
        src: 'https://cdn.axier.io/b4165ce3/thumbs/4.jpg',
        width: 400,
        height: 285,
        tags: [{
            value: 'Penthouse/Roof Terrace',
            title: 'Penthouse/Roof Terrace'
        }],
        caption: 'Penthouse/Roof Terrace',
    }
];