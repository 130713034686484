import ImgsViewer from 'react-images-viewer';

import React, {
  useState
} from 'react'

import { Gallery } from 'react-grid-gallery';

import { images as IMAGES } from './images';

const ViewGallery = () => {
  const [ open, setOpen ] = useState(false);
  const [ currImg, setCurrImg ] = useState(0);

  const handleSelect = (index) => {
    setImgIndex(index);
    setOpen(true);
  };

  const toggleViewer = () => {
    setOpen(!open);

    if(!open) {
      setImgIndex(0);
    }
  }

  const setImgIndex = (index) => {
    setCurrImg(index)
  }

  return (
    <div className="gallery__w">
      <Gallery
        images={IMAGES}
        onClick={handleSelect}
        enableImageSelection={false}
        rowHeight={180}
      />

      <ImgsViewer
        imgs ={
          [
            '1-21406ec5170a0e6a3001d8a9d49a3610',
            '2-9e0115b67cb42078db9ada8ae4d06bab',
            '3-f4a1169a0884d90fb3204347afafca03',
            '7070_22_Option_B-3',
            '4-0264b4322bce93fafa2097a6c2f36821'
          ].map(id => ({
            src: `https://cdn.axier.io/b4165ce3/${id}.jpeg`
          }))
        }
        isOpen={open}
        currImg={currImg}
        showThumbnails={true}
        onClickThumbnail={setImgIndex}
        onClickPrev={() => { return setImgIndex(currImg === 0 ? 0 : currImg - 1)}}
        onClickNext={() => { return setImgIndex(currImg + 1)}}
        onClose={toggleViewer}
      />
    </div>
  )

}

export default ViewGallery;